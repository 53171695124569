//  ##Breakpoints----------------------------------------------------------------------

// Unitless
$xs: 320;
$s: 414;
$m: 768;
$w: 1024;
$sw: 1536;
$container: 1536;
$hd : 1920px;

// With units
$gap: 16px;

$narrow: $s + 0px; // iphone 8 plus width is 414
$medium: $m + 0px;
$wide: $w + 0px;
$superwide: $sw + 0px;
$content-container: $container + 0px !default;

// 414
$small-and-down: "only screen and (max-width : #{$narrow})" !default;
$small-up: "only screen and (min-width : #{$narrow + 1})" !default;
$small-up-to-wide: "only screen and (min-width : #{$narrow + 1}) and (max-width: #{$wide})" !default;
$small-up-to-medium: "only screen and (min-width : #{$narrow + 1}) and (max-width: #{$medium})" !default;

// 768
$medium-up: "only screen and (min-width : #{$medium + 1})" !default;
$medium-and-up: "only screen and (min-width : #{$medium})" !default;
$medium-down: "only screen and (max-width : #{$medium - 1})" !default;
$medium-and-down: "only screen and (max-width : #{$medium})" !default;
$medium-up-to-wide: "only screen and (min-width : #{$medium}) and (max-width: #{$wide})" !default;
$medium-up-to-superwide: "only screen and (min-width : #{$medium + 1}) and (max-width: #{$superwide - 1})" !default;

// 1024
$wide-up: "only screen and (min-width : #{$wide + 1})" !default;
$wide-and-up: "only screen and (min-width : #{$wide})" !default;
$wide-down : "only screen and (max-width : #{$wide - 1})" !default;
$wide-and-down : "only screen and (max-width : #{$wide})" !default;
$wide-up-to-superwide: "only screen and (min-width : #{$wide}) and (max-width: #{$superwide})" !default;
$superwide-and-up: "only screen and (min-width : #{$superwide})" !default;
$wide-up-to-maxwidth: "only screen and (min-width : #{$wide}) and (max-width: #{$content-container})" !default;

// 1440
$maxwidth-down: "only screen and (max-width : #{$content-container - 1})" !default;
$maxwidth-and-up: "only screen and (min-width : #{$content-container})" !default;
$maxwidth-up-to-hd : "only screen and (min-width : #{$content-container + 1}) and (max-width: #{$hd})" !default;

$hd-and-up: "only screen and (min-width : #{$hd})" !default;

// target mobile landscape specifically
$med-mobile-landscape: "only screen and (min-device-width : 414px) and (max-device-width : 768px) and (orientation : landscape)" !default;
$superwide-mobile-landscape: "only screen and (min-device-width : 375px) and (max-device-width : 812px)and (-webkit-device-pixel-ratio : 3)and (orientation : landscape)";
$media-mobile-landscape: "(max-width: 1023px) and (orientation:landscape)";
$media-tablet-portrait-landscape: "(min-width: 1023px) and (orientation:portrait)";
$media-mini-tablet-portrait-landscape: "(min-width: 810px) and (orientation:portrait)";


$content-spacing : 16px;

$grid-max-cols-max : 28;
$grid-max-cols-wide : 25;
$grid-max-cols-small : 8;

@mixin grid-cell($config)
{
  grid-column : map-get($config, mobile-left) / span map-get($config, mobile-span);
  @media #{$medium-and-up} {
    grid-column : map-get($config, tablet-left) / span map-get($config, tablet-span);
  }
  @media #{$wide-up-to-maxwidth} {
    grid-column : map-get($config, tablet-wide-left) / span map-get($config, tablet-wide-span);
  }
  @media #{$maxwidth-and-up} {
    grid-column : map-get($config, desktop-left) / span map-get($config, desktop-span);
  }
}

@mixin gold-background {
  background: #A39161;
}


