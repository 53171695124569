@import "./breakpoints";

/*------------------------------------*\
        $Minimal Reset
\*------------------------------------*/
*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: 100%;
    overflow: hidden;
}

img,
svg {
    vertical-align: top;
}

/*------------------------------------*\
        $Defaults
\*------------------------------------*/

body {
    font-family: Univers Com, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #e9e9e9;
    color: #1b1d1f;
}

/*------------------------------------*\
        $Scroll bar
\*------------------------------------*/
::-webkit-scrollbar-thumb:vertical {
    background-color: #2d2b24;
    height: 50px;
}

::-webkit-scrollbar-thumb:horizontal {
    background-color: #2d2b24;
    height: 16px !important;
}

::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    background-color: transparent;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}

/*------------------------------------*\
        $Fonts
\*------------------------------------*/

@font-face {
    font-family: 'Univers Com';
    src: url('./fonts/UniversCom-55Roman.woff2') format('woff2'),
        url('./fonts/UniversCom-55Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Univers Com';
    src: url('./fonts/UniversCom-65Bold.woff2') format('woff2'),
        url('./fonts/UniversCom-65Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

.gc-component-grid-container {
    position              : relative;
    display               : grid;
    grid-template-columns : $content-spacing repeat(3, 1fr $content-spacing) 1fr $content-spacing;
    grid-template-rows    : auto;

    @media #{$medium-and-up} {
        grid-template-columns : 4*$content-spacing repeat(11, 1fr $content-spacing) 1fr 4*$content-spacing;
        grid-template-rows    : auto;
    }

    @media #{$maxwidth-and-up} {
        grid-template-columns : 4*$content-spacing 2fr ($content-spacing repeat(12, 100px $content-spacing)) 2fr 4*$content-spacing;
        grid-template-rows    : auto;
    }
}

.gc-footer-component {
  width: 100%;
  min-height: 207px;
  background: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media #{$medium-down} {
      min-height: 277px;
  }
}

.gc-footer-top {
  grid-template-rows: auto auto;
}

.gc-footer-footnotes {
  @include grid-cell((
  mobile-left: 2,
  mobile-span: 7,
  tablet-left: 2,
  tablet-span: 20,
  tablet-wide-left: 2,
  tablet-wide-span: 20,
  desktop-left: 4,
  desktop-span: 20,
  ));
}

.gc-footer-logo {
    @include grid-cell((
     mobile-left: 2,
     mobile-span: 7,
     tablet-left: 2,
     tablet-span: 23,
     tablet-wide-left: 2,
     tablet-wide-span: 7,
     desktop-left: 4,
     desktop-span: 7,
    ));
}

.gc-footer-line {
  grid-row: 2;
  @include grid-cell((
   mobile-left: 2,
   mobile-span: 7,
   tablet-left: 2,
   tablet-span: 23,
   tablet-wide-left: 2,
   tablet-wide-span: 23,
   desktop-left: 4,
   desktop-span: 23,
  ));
}

.gc-footer-line-element {
  width: 100%;
  height: 1px;
  margin-top: 40px;
  margin-bottom: 16px;
  @include gold-background;
}

.gc-footer-line-element-bgs {
  background: white;
}

.gc-footer-copyright-cell {
    color: white;

    @include grid-cell((
     mobile-left: 6,
     mobile-span: 3,
     tablet-left: 2,
     tablet-span: 7,
     tablet-wide-left: 2,
     tablet-wide-span: 7,
     desktop-left: 4,
     desktop-span: 7,
    ));

  @media #{$medium-down} {
    justify-self: end;
    grid-row: 1;
  }
}

.gc-footer-menu-items {
  justify-self: end;
    @include grid-cell((
     mobile-left: 2,
     mobile-span: 3,
     tablet-left: 10,
     tablet-span: 15,
     tablet-wide-left: 10,
     tablet-wide-span: 15,
     desktop-left: 12,
     desktop-span: 15,
    ));

  @media #{$medium-down} {
    justify-self: start;
    grid-row: 1;
  }
}

.gc-footer-menu-items-wrapper {
  display: inline-flex;

  @media #{$medium-down} {
    flex-direction: column;
  }
}

.gc-footer-copyright {
  font-size: 12px;
  @media #{$wide-down} {
    font-size: 10px;
  }
}

.gc-footer-menu-item {
  margin-right: 40px;
  font-size: 12px;

  &:last-child {
    margin-right: 0px;
  }

  @media #{$wide-and-down} {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.gc-footer-menu-item {
  color: white;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
